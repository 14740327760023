import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { GatsbyImage } from "gatsby-plugin-image"
import IMGdefault from '../../images/placeholder.png';

const ProjectImg = ({ filename, alt }) => (

  <StaticQuery
    query = {graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    `}


    render = {(data) => {
      const image = data.images.edges.find((n) => n.node.relativePath.includes(filename));

      // if (!image) return null;
      if (!image) return <GatsbyImage image={IMGdefault} alt="Not found" title="Not found" />;

      return <GatsbyImage image={image.node.childImageSharp.gatsbyImageData} alt={`${alt}`} title={`${alt}`} />

    }}
  />
);

ProjectImg.propTypes = {
  filename: PropTypes.string,
  alt: PropTypes.string,
};

export default ProjectImg;
