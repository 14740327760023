import React, { useContext, useEffect, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import Fade from 'react-awesome-reveal';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import CarouselComp from '../Carousel/Carousel';
import IMGci from '../../images/ci.png';

const Projects = () => {
  const { projects } = useContext(PortfolioContext);

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <Title title="My Projects" />

          {projects.map((project) => {
            const {
              id,
              title,
              info,
              info2,
              url,
              repo,
              img,
              alt,
              img2,
              alt2,
              img3,
              alt3,
              img4,
              alt4,
              img5,
              alt5,
              img6,
              alt6,
              img7,
              alt7,
            } = project;

            return (
              <Row key={id}>
                <Col lg={4} sm={12}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={700}
                    distance="30px"
                    triggerOnce={true}
                  >
                    <div className="project-wrapper__text">
                      <h3 className="project-wrapper__text-title">{title || ''}</h3>
                      <div>
                        <p>
                          {<span dangerouslySetInnerHTML={{ __html: sanitizeHtml(info) }} /> || ''}
                        </p>
                        <p className="mb-4 info2">{info2 || ''}</p>
                      </div>

                      {url && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn cta-btn--hero"
                          href={url}
                        >
                          LIVE
                        </a>
                      )}
                      {repo && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn text-color-main"
                          href={repo}
                        >
                          {`<Source Code />`}
                        </a>
                      )}

                      {title === 'Smart Brain 🧠' ? (
                        <div data-tilt className="project-wrapper__stack-icons buffer">
                          <a
                            href="https://github.com/rbhachu/smartbrain-master-docker"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src="/docker.svg"
                              width="30"
                              height="30"
                              alt="Fully Dockerised SmartBrain Client/Server"
                              title="Fully Dockerised SmartBrain Client/Server"
                              className="rounded stack-icons"
                            />
                          </a>
                        </div>
                      ) : (
                        ''
                      )}

                      {title === 'RoboFriends 🤖' ? (
                        <ul data-tilt className="project-wrapper__stack-icons buffer">
                          <li>
                            <a
                              href="https://github.com/rbhachu/robofriends-hooks"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="/react.svg"
                                width="30"
                                height="30"
                                alt="RoboFriends + React Hooks"
                                title="RoboFriends + React Hooks"
                                className="rounded stack-icons"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://github.com/rbhachu/robofriends-hooks-typescript"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="/typescript.svg"
                                width="30"
                                height="30"
                                alt="RoboFriends + TypeScript"
                                title="RoboFriends + TypeScript"
                                className="rounded stack-icons"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://github.com/rbhachu/robofriends-redux"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="/redux.svg"
                                width="30"
                                height="30"
                                alt="RoboFriends + React Redux"
                                title="RoboFriends + React Redux"
                                className="rounded stack-icons"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://github.com/rbhachu/robofriends-redux-testing"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="/enzyme.svg"
                                width="30"
                                height="30"
                                alt="RoboFriends + Enzyme Testing"
                                title="RoboFriends + Enzyme Testing"
                                className="rounded stack-icons"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://github.com/rbhachu/robofriends-redux-testing-ci"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={IMGci}
                                width="30"
                                height="30"
                                alt="RoboFriends + Continuous Integration"
                                title="RoboFriends + Continuous Integration"
                                className="rounded stack-icons"
                              />
                            </a>
                          </li>
                        </ul>
                      ) : (
                        ''
                      )}

                      {title === 'MAME Arcade Machine 🕹️' ? (
                        <ul data-tilt className="project-wrapper__stack-icons">
                          <li>
                            <a
                              href="https://www.youtube.com/watch?v=uw2KwqjZvYg"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="/youtube.svg"
                                width="30"
                                height="30"
                                alt="YouTube - Video 1"
                                title="Youtube - Video 1"
                                className="rounded stack-icons"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.youtube.com/watch?v=t5WsgE0hEKQ"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="/youtube.svg"
                                width="30"
                                height="30"
                                alt="YouTube - Video 2"
                                title="Youtube - Video 2"
                                className="rounded stack-icons"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://web.archive.org/web/20180821170617/http://bhachublog.com/projects/mame-arcade-cabinet-refurb/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="/wayback.svg"
                                width="30"
                                height="30"
                                alt="Wayback Machine - Internet Archive"
                                title="Wayback Machine - Internet Archive"
                                className="rounded stack-icons"
                              />
                            </a>
                          </li>
                        </ul>
                      ) : (
                        ''
                      )}
                    </div>
                  </Fade>
                </Col>

                <Col lg={8} sm={12}>
                  <Fade
                    right={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={1200}
                    distance="30px"
                    triggerOnce={true}
                  >
                    <div className="project-wrapper__image">
                      <div className="thumbnail rounded">

                        <CarouselComp
                            id={id}
                            img={img}
                            alt={alt}
                            img2={img2}
                            alt2={alt2}
                            img3={img3}
                            alt3={alt3}
                            img4={img4}
                            alt4={alt4} 
                            img5={img5}
                            alt5={alt5} 
                            img6={img6}
                            alt6={alt6} 
                            img7={img7}
                            alt7={alt7} 
                          />

                      </div>
                    </div>
                  </Fade>
                </Col>
              </Row>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Projects;
