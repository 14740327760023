import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-awesome-reveal';
import Tilt from 'react-parallax-tilt';
import sanitizeHtml from 'sanitize-html';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';

const Contact = () => {
  const { contact } = useContext(PortfolioContext);
  const { 
    cta, 
    cta2,
    cta3
  } = contact;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);


    // CONTACT FORM

    // temp having to redeclare subheadings, as issue passing via hook state from data.js, as sometimes stating 'undefined'
    const subhead1 = 'Get in touch!<br>Send me a message using the form below and I will get back to you promptly.'
    const subhead2 = 'Thank you!<br>Your message has been submitted successfully, I will aim to get back to you shortly.'
    const subhead3 = 'Error Sending Message!<br>Please contact me via email: <u>mr_bhachu@hotmail.com</u>'
  
    // subheading message
    const [messageStatus, setmessageStatus] = useState(subhead1)

    // form message   
    const [formStatus, setformStatus] = useState(false)

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: ""
    })

    const handleChange = e => {
        const { name, value } = e.target
        setFormData({
            ...formData, 
            [name]: value
        })
    }

    // form validation checks
    const [errors, setErrors] = useState({})
    const validate = (formData) => {
        let formErrors = {}
        // name
        if(!formData.name){
          formErrors.name = "Name required"
        }
        // email
        if(!formData.email){
          formErrors.email = "Email required"
        } 
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if(!formData.email || !emailRegex.test(formData.email)) {
          formErrors.email = 'Valid Email Required'
        }
        // message
        if(!formData.message){
            formErrors.message = "Message is required"
        }
        return formErrors
    }

    const [isSubmitted, setIsSubmitted] = useState(false) // state for sent status

    // onsubmit
    const handleSubmit = e => {
        setErrors(validate(formData))
        setIsSubmitted(true) // submit status sent to sent
        e.preventDefault(); // stop page refresh
    }

    // encode form data
    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
      }

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitted) {

            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({ "form-name": "contact-bhachublog", ...formData })
            })
            //.then(() => alert("Success!")) // testing
            .then(() => setformStatus(true))
            .then(() => setmessageStatus(subhead2))
            .catch(error => setmessageStatus(subhead3))
            //.catch(() => alert(error)) // testing
        }

    }, [errors, formData, isSubmitted, cta, cta2, cta3])
    // console.log(errors, formData)


  return (
    <section id="contact">
      <Container>
        <Title title="Contact Me" />
        <Row className="contact-wrapper">


          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={700} distance="30px" triggerOnce={true}>
              <div className="contact-wrapper__image">
                <div className="contact__image">
                  <Tilt
                    options={{
                      reverse: false,
                      max: 25,
                      perspective: 1000,
                      scale: 1,
                      speed: 300,
                      transition: true,
                      axis: null,
                      reset: true,
                      easing: 'cubic-bezier(.03,.98,.52,.99)',
                    }}
                  >
                    <div data-tilt>

                      {!formStatus ? 
                        (                     
                          <div className="figure">
                            <div className="figure__image-main">
                              <img
                                alt="Rishi Singh Bhachu"
                                title="Rishi Singh Bhachu"
                                src="/avataaars-eyes-open.svg"
                                width="200"
                                height="200"
                              />
                            </div>
                            <div className="figure__image-hover">
                              <img
                                alt="Rishi Singh Bhachu"
                                title="Rishi Singh Bhachu"
                                src="/avataaars-smile-eyes-closed.svg"
                                width="200"
                                height="200"
                              />
                            </div>
                          </div>
                        )
                      :
                        (
                          <img
                            alt="Rishi Singh Bhachu"
                            title="Rishi Singh Bhachu"
                            src="/avataaars-smile-eyes-closed.svg"
                            width="200"
                            height="200"
                          />
                        )
                      }
                    </div>

                  </Tilt>
                </div>
              </div>
            </Fade>
          </Col>


          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1200} distance="30px" triggerOnce={true}>
              <div className="contact-wrapper__info">

                <p className="contact-wrapper__info-text italic-contact">
                  <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(messageStatus) }} />
                </p>

                {!formStatus ? 
                  (
                  <div className="form">

                    <Form
                      name="contact-bhachublog" 
                      method="POST"
                      netlify-honeypot="bot-field"
                      data-netlify="true"
                      action="/"
                      onSubmit={handleSubmit}
                      align="left"
                    >

                      <input type="hidden" name="bot-field" />
                      <input type="hidden" name="form-name" value="contact-bhachublog" />

                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Your Name</Form.Label>
                        <Form.Control 
                          type="text" 
                          label="name" 
                          name="name" 
                          placeholder="name" 
                          value={formData.name} 
                          onChange={handleChange}                        
                          isInvalid={ !!errors.name }
                          size="lg"
                        />
                        <Form.Control.Feedback type='invalid'>
                          { errors.name }
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Your Email address</Form.Label>
                        <Form.Control 
                          type="email" 
                          label="email" 
                          name="email" 
                          placeholder="name@example.com" 
                          value={formData.email} 
                          onChange={handleChange}
                          isInvalid={ !!errors.email }
                          size="lg"
                        />
                        <Form.Control.Feedback type='invalid'>
                          { errors.email }
                        </Form.Control.Feedback> 
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Your Message</Form.Label>
                        <Form.Control 
                          as="textarea" 
                          rows={5} 
                          label="message" 
                          name="message"
                          placeholder="message"  
                          value={formData.message}
                          onChange={handleChange}
                          isInvalid={ !!errors.message }
                          size="lg"
                        />
                        <Form.Control.Feedback type='invalid'>
                          { errors.message }
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Button variant="primary" type="submit" size="lg">
                        Submit
                      </Button>

                    </Form>
                  
                  </div>
                  ) 
                : 
                  (
                  <div className="form">
                    <img
                      alt="Message Sent!"
                      title="Message Sent!"
                      src="/email-sent.svg"
                      width="250"
                      height="250"
                      className="form-submit"
                    />
                  </div>
                  )
                }

              </div>
            </Fade>
          </Col>

        </Row>
      </Container>
    </section>
  );
};

export default Contact;
