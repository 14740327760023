import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import PortfolioContext from '../../context/context';

const Footer = () => {
  
  const { footer } = useContext(PortfolioContext);
  const { networks } = footer;

  return (

    <footer>
      <Container>
        
        <div>
          {networks && networks.map((network) => {
            const { id, name, url } = network;
              return (
                <a 
                  key={id} 
                  href={url} 
                  target="_blank"
                  title={name}
                  className="footerIcons"
                  rel="noopener noreferrer"
                >
                  <i className={`fa fa-${name || 'refresh'} fa-inverse`} />
                </a>
              );
            })}
        </div>

        <div className="footerCopyText">
          <span>© {new Date().getFullYear()} Rishi Singh Bhachu</span>
        </div>

        <div className="disclaimer">
          <span>Built with React, Gatsby, SASS, Bootstrap + PWA Enabled</span>
        </div>

      </Container>
    </footer>

    );
  };
  
  export default Footer;