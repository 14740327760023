import React, { useContext } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';
import LogoImg from "../../images/avataaars.png" // Header logo Image

const Header = () => {
  const { header } = useContext(PortfolioContext);
  const { networks } = header;

return (
  <header className="header" id="topHeader">
    <Container>

        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="navbar fixed-top">
          <Link 
            className="navbar-brand" 
            to="hero" // hero
            smooth={true}
            offset={0} 
            delay={0}
            duration={0} // speeds up responsive after click = 0
            // spy={true}
          >
            <img 
              src={LogoImg} // change img to mine from GH???
              width="25" 
              height="25" 
              alt="Rishi Singh Bhachu | Full Stack Developer Portfolio Site" 
              title="Rishi Singh Bhachu | Full Stack Developer Portfolio Site" 
              className="rounded" // adds nice round edges to image
            />
          </Link>          
          
        <Navbar.Toggle aria-controls="responsive-navbar-nav"  />
        
          <Navbar.Collapse id="responsive-navbar-nav">
            <div className="headerTopLinks">
            <Nav>

            {networks && networks.map((network) => {
              const { id, name, url } = network;

              return (
                <Nav.Link key={id}>
                  <Link 
                    to={url}
                    smooth={true}
                    offset={-45} // -45 best setting
                    delay={0}
                    duration={0} // speeds up responsive after click = 0
                    // spy={true}
                    // isDynamic={true}
                    // ignoreCancelEvents={false}
                    // title={name}
                    className={url}
                  >
                    {name}
                  </Link>
                </Nav.Link>
              );

            })}


            </Nav>
            </div>
          </Navbar.Collapse>
          
        </Navbar>

    </Container>
  </header>
  );
};

export default Header;